@use "igniteui-angular/theming" as *;
@use "variables" as *;
@use "app-layout";

/* autoprefixer grid: on */
html,
body {
    height: 100%;
    overflow: auto;
    margin: 0;
}

@include core();
@include typography();
@include theme($palette: $palette, $schema: $schema);

:root {
  @include palette($palette);
}

.light-theme {
  @include light-theme($palette);
}

.dark-theme {
  background: #333;
  color: #fff;
  @include dark-theme($palette: $green-palette);

  .grid-chart-contextmenu-wrapper {
    @include fluent-dark-theme($fluent-excel-palette);
  }
}

.fin-dark-theme {
  @include dark-theme($green-palette);
  background: #333;

  ::-moz-placeholder {
    opacity: 1;
  }
}
.igx-time-picker__item--selected {
  color: #09f;
}

igx-time-picker {
  //width: 400px;
  background-color: #fff;
}
.igx-input-group__bundle::after {
  display: none;
}

.igx-input-group__bundle::-moz-focus-inner {
  display: none;
  color: #fff;
}

.igx-input-group__line {
  color: #fff;
}

.igx-input-group__line {
  color: #fff;
}

.igx-time-picker__header {
  background-color: aliceblue;
}
.ig-typography .igx-button {
  color: rgb(13, 13, 14);
}

.ig-typography .igx-button:hover {
  color: #09f;
  background-color: aliceblue;
}
.igx-input-group--focused .igx-input-group__line {
  transform: none !important;
  transition: none !important;
  color: #fff;
  background-color: #fff;
}

.igx-time-picker__item:hover {
  color: #09f;
}
$my-time-picker-theme: time-picker-theme(
  $text-color: #e4c8a5,
  $hover-text-color: #ecaa53,
  $selected-text-color: #ecaa53,
  $header-background: #ecaa53,
  $header-hour-text-color: #011627,
  $header-time-period-color: #011627,
  $background-color: #011627,
);

$margin: 8px 8px 8px 0px;
$actions-height: 52px;

.action-buttons {
  display: flex;
  margin-left: 24px;
  justify-content: flex-end;
  align-items: center;
  height: $actions-height;

  [igxButton] {
    margin: $margin;
  }
}

$dark-button: button-theme(
  $foreground: #ecaa53,
  $hover-background: #ecaa53,
);

:host ::ng-deep {
  @include css-vars($my-time-picker-theme);
  @include css-vars($dark-button);
}
@import "assets/scss/variables";

/* You can add global styles to this file, and also import other style files */
$page_max_size: 1200px;

@for $i from 1 through 4 {
  .bg-primary.bg-light-#{$i} {
    background-color: lighten($color-primary, $i * 7%) !important;
  }
  .bg-success.bg-light-#{$i} {
    background-color: lighten($color-success, $i * 7%) !important;
  }
  .bg-warning.bg-light-#{$i} {
    background-color: lighten($color-warning, $i * 7%) !important;
  }
  .bg-danger.bg-light-#{$i} {
    background-color: lighten($color-danger, $i * 7%) !important;
  }
}

$header_height: 45px;
$menu_padding: 8px;
$footer_height: 37px;

main {
  max-width: $page_max_size;
  margin: auto;

  .main-container {
    min-height: calc(
      100vh - #{$header_height + 2 * $menu_padding + $footer_height}
    );
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 2px !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  height: 25px !important;
}

.ng-value-container {
  margin-top: 7px !important;
  overflow-y: auto !important;
  height: 100% !important;
}
.ng-select .ng-select-container .ng-value-container {
  align-items: start !important;
}

.pac-container {
  z-index: 100000 !important;
  background-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
}

div.pac-item {
  align-items: center;
  align-content: center;
  min-height: 45px !important;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  position: relative;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.pac-icon .pac-icon-marker {
  background-color: red;
}

.avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-outer-border {
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;

  &:last-child {
    border-right: 0 !important;
  }
}

a[disabled] {
  pointer-events: none;
}

.spinner-grow {
  width: 1em !important;
  height: 1em !important;
}

.pagination-wrapper {
  overflow-x: auto;
}

@media (min-width: 768px) {
  .pagination-wrapper {
    max-width: calc(100% - 202px);
  }
}

// ng-select
.ng-select {
  .ng-select-container {
    min-height: 24px !important;
    height: 24px !important;
    border: none !important;

    .ng-value-container {
      padding-left: 0 !important;

      .ng-value {
        .ng-value-label {
          .small {
            display: none;
          }
        }
      }

      .ng-input {
        top: 0 !important;
        left: 0 !important;
        padding-left: 0 !important;

        input {
          cursor: text !important;
        }
      }
    }
  }

  .ng-dropdown-panel {
    border: none !important;
  }
}

app-datalist-select.app-datalist-select-double-height {
  .ng-select {
    height: calc(8rem + 4px);

    .ng-select-container {
      height: calc(5.75rem + 4px) !important;
      align-items: flex-start;
    }
  }
}

app-datalist-select.app-datalist-select-without-label {
  label {
    height: 0 !important;
  }
  .ng-select {
    padding-top: 10px !important;
    height: calc(5rem + 4px);

    .ng-select-container {
      height: calc(4rem) !important;
      align-items: flex-start;
    }
  }
}

app-datalist-select.app-datalist-select-half-height {
  .ng-select {
    height: calc(5rem + 4px);

    .ng-select-container {
      height: calc(rem(+4px)) !important;
      align-items: flex-start;
    }
  }
}

app-datalist-select.app-datalist-select-double-height {
  .ng-select {
    height: calc(8rem + 4px);

    .ng-select-container {
      height: calc(5.75rem + 4px) !important;
      align-items: flex-start;
    }
  }
}

.bs-tooltip-start.tooltip {
  padding-top: 9px;
}
// Static menu version of the content
$menu_open_size: 280px;
$menu_small_size: 70px;

@media (min-width: 768px) {
  body.has-expandable-menu {
    &.menu-expanded {
      overflow: auto;
    }

    app-headermenu {
      app-user-card {
        display: none;
      }
    }

    .sidebar-wrap {
      left: 0;
      opacity: 1;
      width: $menu_open_size;

      .sidebar {
        left: 0;
        width: $menu_open_size;
      }
    }

    main.home-layout {
      padding-left: $menu_open_size;
      max-width: none;
    }

    app-staticfooter,
    app-headermenu {
      padding-left: $menu_open_size;
    }

    &:not(.menu-expanded) {
      .sidebar-wrap {
        width: $menu_small_size;

        .sidebar {
          width: $menu_small_size;

          .nav {
            .dropdown-menu {
              min-width: 0;
              padding: 0;
            }

            .nav-link {
              .col,
              .arrow {
                display: none !important;
              }

              .icon {
                margin-right: 0;
              }
            }
          }
        }
      }

      main.home-layout {
        padding-left: $menu_small_size;
      }

      app-staticfooter,
      app-headermenu {
        padding-left: $menu_small_size;
      }
    }
  }
}

.dark-mode tspan {
  fill: #888888;

  &:first-child {
    fill: #ffffff;
  }
}
.top-20 {
  margin-top: -15px !important;
}

.dark-mode .log-information.bubble-sheet li p {
  background: #4b4d4e;
}
.dark-mode .log-information.bubble-sheet li p:before {
  border-right: 10px solid #4b4d4e;
}

.dark-mode .ng-select .ng-select-container {
  background-color: #000000;
  color: #ffffff;
}

.log-information {
  & > li {
    padding-right: 0;
  }
}

.dark-mode .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #000000;
  color: #ffffff;
}

.dark-mode .ng-option.ng-option-marked {
  background-color: #000000 !important;
  color: #7297f8 !important;
}

.dark-mode .ng-option.ng-option-selected {
  background-color: #4b4d4e !important;
  color: #ffffff !important;
}

.dark-mode .ng-select .ng-select-container {
  background-color: #000000 !important;
  color: #ffffff;
}

// full calendar
.fc {
  border-radius: 5px;
  overflow: hidden;

  a {
    text-decoration: none;
  }
  .fc-toolbar-title {
    margin: 0 !important;
    font-size: 16px !important;
  }
  .fc-button {
    padding: 0.275em 0.475em;
    font-size: 0.95em;
    line-height: 1.7;
  }
  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0em !important;
    padding: 10px 15px !important;

    .fc-button {
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
  &.fc-theme-standard {
    table {
      border: 0 none;
      border-collapse: collapse;
      th {
        font-size: 14px;
        text-decoration: none;
        padding: 10px 0;
        line-height: 20px;
        border: none;

        a.fc-col-header-cell-cushion {
          font-weight: normal;
        }
      }

      td {
        border: none;
      }
      table {
        td {
          background-color: transparent;
          color: inherit;
          font-size: 14px;
          text-decoration: none;
          padding: 0;

          a {
            color: inherit;
            font-weight: normal;
          }

          .fc-daygrid-more-link {
            font-size: 10px;
          }
          .fc-daygrid-day-events {
            margin-top: -8px;
          }

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }
        }
        tr:first-child {
          td {
            border-bottom: none;
          }
        }
        tr:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .fc.fc-theme-standard table table td .fc-daygrid-more-link {
    height: 8px;
    width: 8px;
    border-radius: 5px;
    text-indent: 15px;
    overflow: hidden;
    display: block;
  }
}

.form-floating > .form-control[type="file"] {
  padding-top: 1.75rem;
}

// Helpers from bootstrap 5.1
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.ck-editor__editable {
  min-height: 140px;
  min-width: 100%;
  --ck-border-radius: 5px;
  --ck-color-base-border: #ffffff;
}
.ck-focused {
  border: none !important;
}

.ck.ck-toolbar {
  border: 0;
  --ck-color-toolbar-border: #ffffff;
}
